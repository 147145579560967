<template>
<v-layout>
    <v-app-bar
    absolute
    color="#fff"
  >
  
    <img src="@/assets/logo-pharmacall.jpg" height="60" />
    <v-spacer></v-spacer>
    <p class="header__text" v-if="authService.isLogged()">
      Hola Admin
    </p>
    <p class="header__signout" @click="authService.logout()" v-if="authService.isLogged()">
      Desconectar
      <v-icon class="header__signout-icon">mdi-close</v-icon>
    </p>
  </v-app-bar>  
  <div class="home"  style="background-color:#f7f7f7">       
       
        <v-container style="padding-bottom:100px !important;">
            <v-card>    
              <v-tabs
                v-model="tab"
                fixed-tabs
            >
                <v-tab key="usuarios">
                Usuarios
                </v-tab>
                <v-tab key="cupones">
                Cupones
                </v-tab>
                <v-tab key="importacion">
                Importación
                </v-tab>
                <v-tab key="admin">
                Administradores
                </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab" style="color:##f7f7f7">
                <v-tab-item                    
                    key="usuarios"
                >
                    <v-card flat>
                        <v-card-title>
                            <v-text-field
                                v-model="searchUser"
                                label="Buscar usuario"
                                single-line
                                hide-details
                            />                          
                            <div class="flex-grow-1" />
                            <v-btn                                
                                default
                                color="primary"                                
                                @click="exportarUsuarios">
                                Exportar Usuarios
                            </v-btn>              
                        </v-card-title>
                        <v-data-table
                            :search="searchUser"
                            :loading="loading"
                            :headers="[
                                { text: 'Código Farmacia', value: 'cod_pharma', align: 'center', sortable: true },
                                { text: 'Email', value: 'email', align: 'center', sortable: true },
                                { text: 'Nombre', value: 'name', align: 'center', sortable: true },            
                                { text: 'Usado', value: 'used', align: 'center', sortable: true },
                                { text: 'Disponible', value: 'available', align: 'center', sortable: true },
                                { text: 'Activado', value: 'activated', align: 'center', sortable: true },
                            ]"
                            :items="usuarios"                  
                            item-key="name"
                            class="elevation-1"                  
                            loading-text="Cargando usuarios ..."
                        >
                        <template v-slot:[`item.activated`]="{ item }">                            
                            <v-icon class="header__signout-icon" v-if="item.activated === true">mdi-check</v-icon>
                            <v-icon class="header__signout-icon" v-if="item.activated === false">mdi-close</v-icon>
                        </template>                        
                        <template v-slot:[`item.used`]="{ item }">
                            {{ item.used.toFixed(2) }}€
                        </template>
                        <template v-slot:[`item.available`]="{ item }">
                            {{ item.available.toFixed(2) }}€
                        </template>
                        </v-data-table>
                    </v-card>
                </v-tab-item>
                <v-tab-item                    
                    key="cupones"
                >
                    <v-card flat>
                        <v-card-title>
                        <div class="flex-grow-1" />
                        <v-text-field
                            v-model="searchCoupon"
                            label="Buscar cupon"
                            single-line
                            hide-details
                        />
                        </v-card-title>
                        <v-data-table
                            :loading="loading"
                            :search="searchCoupon"
                            :headers="[
                                { text: 'Código del cupon', value: 'code', align: 'center', sortable: true },
                                { text: 'Fecha de importación', value: 'createdAt', align: 'center', sortable: true },
                                { text: 'Fecha de canjeo', value: 'used', align: 'center', sortable: true },
                                { text: 'Valor del cupon', value: 'amount', align: 'center', sortable: true },
                            ]"
                            :items="cupones"                  
                            item-key="name"
                            class="elevation-1"                  
                            loading-text="Cargando cupones ..."
                        >
                        <template v-slot:[`item.createdAt`]="{ item }">
                            {{ item.createdAt ? formatDate(item.createdAt) : '' }}
                        </template>
                        <template v-slot:[`item.used`]="{ item }">
                            {{ item.used ? formatDate(item.used) : '' }}
                        </template>                        
                        <template v-slot:[`item.amount`]="{ item }">
                            {{ item.amount }}€
                        </template>
                        </v-data-table>                        
                    </v-card>
                </v-tab-item>
                <v-tab-item                    
                    key="importacion"
                >
                    <v-card flat>
                        <v-card-text>
                            <v-form style="margin-top:20px;">
                            <v-container>
                            <v-row>
                                <v-col
                                cols="12"
                                md="8"
                                >
                              <v-file-input
                                v-model="usersFile"
                                accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                label="Seleccione fichero excel de usuarios"
                                ></v-file-input>
                            </v-col>
                            <v-col
                                cols="16"
                                md="4"
                                >
                            <v-btn
                                :disabled="usersFile === null"
                                default
                                color="primary"
                                style="margin-top:12px;"
                                @click="importarUsuarios"
                            >
                                Importar Usuarios
                            </v-btn>
                                </v-col>
                            </v-row>                         
                            <v-row>
                                <v-col
                                cols="12"
                                md="8"
                                >
                              <v-file-input                              
                                v-model="cuponesFile"
                                accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                label="Seleccione fichero excel de cupones"
                                ></v-file-input>
                            </v-col>
                            <v-col
                                cols="16"
                                md="4"
                                >
                            <v-btn
                                :disabled="cuponesFile === null"
                                default
                                color="primary"
                                style="margin-top:12px;"
                                @click="importarCupones"
                            >
                                Importar Cupones
                            </v-btn>
                                </v-col>
                            </v-row>                            
                            </v-container>
                        </v-form>                            
                        </v-card-text>
                    </v-card>
                </v-tab-item>
                <v-tab-item                    
                    key="admin"
                >
                    <v-card flat>
                        <v-card-title>
                        <div class="flex-grow-1" />
                        <v-text-field
                            v-model="searchAdmin"
                            label="Buscar administrador"
                            single-line
                            hide-details
                        />
                        </v-card-title>
                        <v-data-table
                            :search="searchAdmin"
                            :loading="loading"
                            :headers="[                                
                                { text: 'Email', value: 'email', align: 'center', sortable: true },
                                { text: 'Nombre', value: 'name', align: 'center', sortable: true },
                                { text: 'Activo', value: 'activated', align: 'center', sortable: true },
                            ]"
                            :items="admins"                  
                            item-key="name"
                            class="elevation-1"                  
                            loading-text="Cargando administradores ..."
                        >
                        <template v-slot:[`item.date`]="{ item }">
                            {{ formatDate(item.date) }}
                        </template>
                        <template v-slot:[`item.amount`]="{ item }">
                            {{ item.amount }}€
                        </template>
                        </v-data-table>
                    </v-card>
                </v-tab-item>
            </v-tabs-items>
             </v-card>
        </v-container>  
      
        <div class="home__copyright">
            <div class="home__copyright__container">
                <span class="copyright">© Copyright - Pharma Call<div style="float:right;"> 
                    <router-link to="/condiciones-de-la-promocion" style="color:white;text-decoration:none;">Condiciones de la promoción</router-link> &nbsp;|&nbsp;
                    <a style="color:white;text-decoration:none;" target="new" href="https://www.pharma-call.com/politica-privacidad/">Política de privacidad</a> &nbsp;|&nbsp; <a target="new" style="color:white;text-decoration:none;" href="https://www.pharma-call.com/politica-de-cookies/">Política de cookies</a> </div></span>
            </div>
        </div>            
  </div>
      <v-row justify="center">
      <v-dialog
        v-model="dialogImport"
        persistent
        max-width="600px"
      >
        <v-card>
          <v-card-title>
            <span class="headline">Resultado importación</span>
          </v-card-title>
          <v-card-text>
            Resultado: <b>{{ importResult }}</b><br/>
            Registros importados:  <b>{{ importOk }}</b><br/>
            Registros actualizados:  <b>{{ importUpdated }}</b><br/>
            Registros ignorados:  <b>{{ importIgnored }}</b><br/>
            <v-container />
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              default
              color="primary"
              @click="dialogImport = false"
            >
              Cerrar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
</v-layout>
</template>

<script>
import httpService from '@/services/httpService'
import AuthService from '@/services/authService'
import moment from 'moment'

export default {
  name: 'Home',
  layout: 'Admin',
  data () {
      return {
        importResult: 'OK',
        importUpdated: 0,
        importOk: 0,
        importIgnored: 0,
        dialogImport: false,
        loading: false,
        usersFile: null,
        cuponesFile: null,
        usuarios: [],
        admins: [],
        tab:'',
        authService: AuthService,
        seccion: 'home',
        cliente: 'Farmacia Pepito',
        puntos: 0,
        cupon: 'AAAA-BBBB-CCCC-DDDD',
        generate: false,
        sub_seccion: '',
        search: '',
        searchCoupon: '',
        searchUser: '',
        searchAdmin: '',
        errors: [],
        cupones: [],
        password: '',
        npassword: '',
        rpassword: '',
      }
  },
  watch: {
  },
  mounted () {
    this.getProfile()
    this.getUsers()
    this.getCupones()
  },
  methods: {
    formatDate(item) {
        return moment(item).format('DD/MM/yyyy HH:mm');
    }, 
    async getCupones() {
        httpService.get('/admin/coupons')
        .then( res => {
            if ( res.data ) {
                this.cupones = res.data
            }
        })
        .catch( err => {
            console.log(err)
        })
    },    
    async getUsers() {
        this.loading = true
        httpService.get('/users')
        .then( res => {
            if ( res.data && res.data ) {      
                this.usuarios = res.data.filter(x => x.role ==='user')
                this.admins = res.data.filter(x => x.role ==='admin')
                this.loading = false
            }
        })
        .catch( err => {
            console.log(err)
        })
    },    
    async getProfile() {
        httpService.get('/profile')
        .then( res => {
            if ( res.data && res.data.profile && res.data.profile.role === 'admin' ) {                
                const profile = res.data.profile
                AuthService.setUser(profile)
                this.cliente = profile.name
                this.puntos = profile.available.toFixed(2);
                this.cupones = profile.coupons
            } else {                 
                this.$router.push('/');            
            }
        })
        .catch( err => {
            console.log(err)
        })
    },
    setPassword() {
        httpService.get('/admin/users/export')
            .then( res => {
                console.log(res)
            });
    },
    async exportarUsuarios() {
        httpService.getBlob('/admin/users/export')
            .then(response => {
                const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
                const link = document.createElement('a')
                link.href = URL.createObjectURL(blob)
                link.download = 'export-usuarios-' + new Date().toJSON().slice(0,10)
                link.click()
                URL.revokeObjectURL(link.href)
            })
    },
    importarUsuarios() {        
        const reader = new FileReader();
        
        // Use the javascript reader object to load the contents
        // of the file in the v-model prop
        reader.readAsDataURL(this.usersFile);
        reader.onload = () => {
            const data = /base64,(.+)/.exec(reader.result)[1];
            this.loading = true
            httpService.post('/admin/users/import',{ file:data})           
            .then( (res) => {
                if (res && res.data ) {
                    this.importResult = 'OK'
                    this.importOk = res.data.added
                    this.importUpdated = res.data.updated
                    this.importIgnored = res.data.error
                    this.getUsers()
                } else {
                    this.importResult = 'ERROR'
                    this.importOk = 0
                    this.importUpdated = 0
                    this.importIgnored = 0
                }
                this.dialogImport = true
                this.usersFile = ""
            })
            .catch( () => {
                this.importResult = 'ERROR'
                this.importOk = 0
                this.importUpdated = 0
                this.importIgnored = 0
                this.dialogImport = true
                this.usersFile = null
            })            
            .finally(() => this.loading = false)
        }
    },
    async importarCupones() {
        const reader = new FileReader();
        
        // Use the javascript reader object to load the contents
        // of the file in the v-model prop
        reader.readAsDataURL(this.cuponesFile);
        reader.onload = () => {
            const data = /base64,(.+)/.exec(reader.result)[1];
            httpService.post('/admin/coupons/import',{ file:data})
            .then( (res) => {
                if (res && res.data ) {
                    this.importResult = 'OK'
                    this.importOk = res.data.added
                    this.importUpdated = res.data.updated
                    this.importIgnored = res.data.ignored
                    this.getCupones()
                } else {
                    this.importResult = 'ERROR'
                    this.importOk = 0
                    this.importUpdated = 0
                    this.importIgnored = 0
                }
                this.dialogImport = true
            })
            .catch(function (error) {
                console.log(error);
                this.importResult = 'ERROR'
                this.importOk = 0
                this.importUpdated = 0
                this.importIgnored = 0
                this.dialogImport = true
                this.usersFile = ""
            })

        }
        //const data = btoa(reader.readAsBinaryString(this.cuponesFile.files[0]))
        //console.log(data)
    }
  }
}
</script>

<style lang="scss" scoped>
    @import '@/variables';

    body {
        background-color:white;
    }

  .v-app-bar {
    padding: 10px 0 10px 0;
    height: $header_auth_size_mobile !important;
    box-shadow: none !important;
    .v-toolbar__content {
      height: 100% !important;
      .header__points {
        margin: 0;
        font-size: 15px;
        color: $primary-color;
        font-weight: 500;
        margin-right: 25px;
        &-icon {
          font-size: 15px;
          margin-right: 5px;
          vertical-align: baseline;
          color: white;
          background: linear-gradient(to right, $primary_color 50%, black 50%);
          border-radius: 50%;
        }
      }
      .header__text {
        margin: 0;
        font-size: 15px;
        font-weight: 400;
        color: black;
        margin-right: 25px;
        span {
          color: $primary_color;
        }
      }
      .header__signout {
        margin: 0;
        font-size: 15px;
        font-weight: 400;
        color: black;
        margin-right: 25px;
        cursor: pointer;
        &:hover {
          border-bottom: 1px solid black;
        }
        &-icon {
          font-size: 18px;
          color: black;
          vertical-align: text-bottom;
        }
      }
    }
  }

    .home {
        margin-top: $header_auth_size_mobile;
        color: $text_color;
        background-color:white;
        width: 100%;
        font-weight: 400;
        margin-left: auto;
        margin-right: auto;
        @media (min-width: 960px) { 
            margin-top: $header_auth_size_desktop;
        }
        &__cupones {
            margin-bottom:40px;
        }
        &__password {
            max-width:1100px;
        }
        &__footer {
            margin-top:20px;
            background-color:#109bc5;
            min-height: 300px;
        }
        &__copyright {
            bottom: 0;
            position: absolute;
            left: 0;
            width: 100%;            
            background-color:#11abd6;
            min-height: 50px;
            &__container {
                color:white;
                margin: 0 auto;
                max-width:1100px;
                padding-top:15px;
                font-size:85%;                
            }
        }
        &__banner {
            background-color: $primary-color;
            min-height: 230px;
            padding: 20px;
            color: white;
            text-align: center;
            font-size: 22px;
            display: flex;
            line-height: 28px;
            @media (min-width: 960px) { 
                font-size: 26px;
                line-height: 32px;
            }
            &__text {
                margin: auto auto 30px auto;
                font-weight: bold;
                &-client {
                    color: black;
                }
                &-subtext {
                    font-weight: 400;
                }
            }
        }
        &__points, &__exchange {
            background-color: white;
            border-radius: 10px;
            margin: 20px auto;
            padding: 20px;
            text-align: center;
            color: black;
            max-width: 400px;
            @media (min-width: 600px) {
                margin: 60px 20px 60px 20px;
                padding: 30px;
            }
        }
        &__coupon {            
            border-radius: 10px;
            margin: 5px auto;
            padding: 5px;
            margin-bottom: 40px;
            text-align: center;
            color: black;
            max-width: 600px;
            @media (min-width: 600px) {                
                padding: 30px;
            }
            &-icon {
                color: black;                
                border-radius: 50%;
                font-size: 40px;
                padding: 3px;
            }
            &-text {
                margin-top: 10px;
                font-weight: bold;
                font-size: 13px;
                margin-bottom: 0;
            }
            &-coupon {
                font-size: 30px;
                color: $primary-color;
                padding:5px;
                font-weight: bold;
                margin-bottom: 0;
            }
            &-subtext {
                color: black;
                margin-bottom: 0;
                font-size: 12px;
            }               
        }
        &__points {
            &-icon {
                color: white;
                background-color: black;
                border-radius: 50%;
                font-size: 15px;
                padding: 3px;
            }
            &-text {
                margin-top: 10px;
                margin-bottom: 0;
            }
            &-points {
                font-size: 30px;
                color: $primary-color;
                font-weight: bold;
                margin-bottom: 0;
            }
            &-subtext {
                color: $primary-color;
                margin-bottom: 0;
            }
        }
        &__exchange {
            &-title {
                font-weight: bold;
                margin-bottom: 6px;
            }
            &-subtitle {
                color: $text_color;
                font-size: 14px;
                line-height: 18px;
                margin-bottom: 20px;
            }
            &-button {
                text-transform: none;
                padding: 20px 15px !important;
            }
        }
        &__personal {
            background-color: white;
            padding: 60px 20px;
            &-title {
                text-align: center;
                font-weight: 400;
                color: $primary-color;
                margin-bottom: 30px;
            }
            &__cards {
                @media (min-width: 600px) {
                    display: flex;
                }
                &__card {
                    border: 2px solid $primary-color;
                    display: flex;
                    margin: 10px 20px;
                    padding: 15px;
                    cursor: pointer;
                    &:hover {
                        transform: scale(1.05);
                    }
                    &-icon {
                        color: $primary-color;
                        margin-right: 8px;
                    }
                    &-text {
                        color: $text-color;
                        font-size: 14px;
                        margin: auto;
                    }
                }
            }
        }
        &__info {
            background-color: white;
            max-width: 1100px;            
            margin: 0 auto;
            padding: 30px 10px;
            &-title {
                text-align: center;
                font-weight: 400;
                color: $primary-color;
                margin-bottom: 10px;
            }
            &-subtitle {
                color: $text-color;
                text-align: center;
                font-size: 14px;
                line-height: 18px;
                max-width: 700px;
                margin: auto;
                padding-bottom: 50px;
            }
            &__cards {
                display: flex;
                &__card {
                    position: relative;
                    max-width: 80%;
                    padding: 10;
                    line-height: 0;
                    img {
                        width: 100%;
                        height: auto;
                        overflow: hidden;
                        border-radius: 50%;
                    }
                    &-text {
                        position: absolute;
                        top: 50%;
                        width: 100%;
                        text-align: center;
                        color: white;
                    }
                    &-overlay {
                        opacity: 0.5;
                        background-color: #333333;
                        position: absolute;
                        border-radius: 50%;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        overflow: hidden;
                    }
                }
            }
        }
    }
</style>
