var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',[_c('v-app-bar',{attrs:{"absolute":"","color":"#fff"}},[_c('img',{attrs:{"src":require("@/assets/logo-pharmacall.jpg"),"height":"60"}}),_c('v-spacer'),(_vm.authService.isLogged())?_c('p',{staticClass:"header__text"},[_vm._v(" Hola Admin ")]):_vm._e(),(_vm.authService.isLogged())?_c('p',{staticClass:"header__signout",on:{"click":function($event){return _vm.authService.logout()}}},[_vm._v(" Desconectar "),_c('v-icon',{staticClass:"header__signout-icon"},[_vm._v("mdi-close")])],1):_vm._e()],1),_c('div',{staticClass:"home",staticStyle:{"background-color":"#f7f7f7"}},[_c('v-container',{staticStyle:{"padding-bottom":"100px !important"}},[_c('v-card',[_c('v-tabs',{attrs:{"fixed-tabs":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',{key:"usuarios"},[_vm._v(" Usuarios ")]),_c('v-tab',{key:"cupones"},[_vm._v(" Cupones ")]),_c('v-tab',{key:"importacion"},[_vm._v(" Importación ")]),_c('v-tab',{key:"admin"},[_vm._v(" Administradores ")])],1),_c('v-tabs-items',{staticStyle:{"color":"##f7f7f7"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',{key:"usuarios"},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_c('v-text-field',{attrs:{"label":"Buscar usuario","single-line":"","hide-details":""},model:{value:(_vm.searchUser),callback:function ($$v) {_vm.searchUser=$$v},expression:"searchUser"}}),_c('div',{staticClass:"flex-grow-1"}),_c('v-btn',{attrs:{"default":"","color":"primary"},on:{"click":_vm.exportarUsuarios}},[_vm._v(" Exportar Usuarios ")])],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"search":_vm.searchUser,"loading":_vm.loading,"headers":[
                                { text: 'Código Farmacia', value: 'cod_pharma', align: 'center', sortable: true },
                                { text: 'Email', value: 'email', align: 'center', sortable: true },
                                { text: 'Nombre', value: 'name', align: 'center', sortable: true },            
                                { text: 'Usado', value: 'used', align: 'center', sortable: true },
                                { text: 'Disponible', value: 'available', align: 'center', sortable: true },
                                { text: 'Activado', value: 'activated', align: 'center', sortable: true } ],"items":_vm.usuarios,"item-key":"name","loading-text":"Cargando usuarios ..."},scopedSlots:_vm._u([{key:"item.activated",fn:function(ref){
                            var item = ref.item;
return [(item.activated === true)?_c('v-icon',{staticClass:"header__signout-icon"},[_vm._v("mdi-check")]):_vm._e(),(item.activated === false)?_c('v-icon',{staticClass:"header__signout-icon"},[_vm._v("mdi-close")]):_vm._e()]}},{key:"item.used",fn:function(ref){
                            var item = ref.item;
return [_vm._v(" "+_vm._s(item.used.toFixed(2))+"€ ")]}},{key:"item.available",fn:function(ref){
                            var item = ref.item;
return [_vm._v(" "+_vm._s(item.available.toFixed(2))+"€ ")]}}],null,true)})],1)],1),_c('v-tab-item',{key:"cupones"},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_c('div',{staticClass:"flex-grow-1"}),_c('v-text-field',{attrs:{"label":"Buscar cupon","single-line":"","hide-details":""},model:{value:(_vm.searchCoupon),callback:function ($$v) {_vm.searchCoupon=$$v},expression:"searchCoupon"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"loading":_vm.loading,"search":_vm.searchCoupon,"headers":[
                                { text: 'Código del cupon', value: 'code', align: 'center', sortable: true },
                                { text: 'Fecha de importación', value: 'createdAt', align: 'center', sortable: true },
                                { text: 'Fecha de canjeo', value: 'used', align: 'center', sortable: true },
                                { text: 'Valor del cupon', value: 'amount', align: 'center', sortable: true } ],"items":_vm.cupones,"item-key":"name","loading-text":"Cargando cupones ..."},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
                            var item = ref.item;
return [_vm._v(" "+_vm._s(item.createdAt ? _vm.formatDate(item.createdAt) : '')+" ")]}},{key:"item.used",fn:function(ref){
                            var item = ref.item;
return [_vm._v(" "+_vm._s(item.used ? _vm.formatDate(item.used) : '')+" ")]}},{key:"item.amount",fn:function(ref){
                            var item = ref.item;
return [_vm._v(" "+_vm._s(item.amount)+"€ ")]}}],null,true)})],1)],1),_c('v-tab-item',{key:"importacion"},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('v-form',{staticStyle:{"margin-top":"20px"}},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('v-file-input',{attrs:{"accept":"application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet","label":"Seleccione fichero excel de usuarios"},model:{value:(_vm.usersFile),callback:function ($$v) {_vm.usersFile=$$v},expression:"usersFile"}})],1),_c('v-col',{attrs:{"cols":"16","md":"4"}},[_c('v-btn',{staticStyle:{"margin-top":"12px"},attrs:{"disabled":_vm.usersFile === null,"default":"","color":"primary"},on:{"click":_vm.importarUsuarios}},[_vm._v(" Importar Usuarios ")])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('v-file-input',{attrs:{"accept":"application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet","label":"Seleccione fichero excel de cupones"},model:{value:(_vm.cuponesFile),callback:function ($$v) {_vm.cuponesFile=$$v},expression:"cuponesFile"}})],1),_c('v-col',{attrs:{"cols":"16","md":"4"}},[_c('v-btn',{staticStyle:{"margin-top":"12px"},attrs:{"disabled":_vm.cuponesFile === null,"default":"","color":"primary"},on:{"click":_vm.importarCupones}},[_vm._v(" Importar Cupones ")])],1)],1)],1)],1)],1)],1)],1),_c('v-tab-item',{key:"admin"},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_c('div',{staticClass:"flex-grow-1"}),_c('v-text-field',{attrs:{"label":"Buscar administrador","single-line":"","hide-details":""},model:{value:(_vm.searchAdmin),callback:function ($$v) {_vm.searchAdmin=$$v},expression:"searchAdmin"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"search":_vm.searchAdmin,"loading":_vm.loading,"headers":[                                
                                { text: 'Email', value: 'email', align: 'center', sortable: true },
                                { text: 'Nombre', value: 'name', align: 'center', sortable: true },
                                { text: 'Activo', value: 'activated', align: 'center', sortable: true } ],"items":_vm.admins,"item-key":"name","loading-text":"Cargando administradores ..."},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
                            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.date))+" ")]}},{key:"item.amount",fn:function(ref){
                            var item = ref.item;
return [_vm._v(" "+_vm._s(item.amount)+"€ ")]}}],null,true)})],1)],1)],1)],1)],1),_c('div',{staticClass:"home__copyright"},[_c('div',{staticClass:"home__copyright__container"},[_c('span',{staticClass:"copyright"},[_vm._v("© Copyright - Pharma Call"),_c('div',{staticStyle:{"float":"right"}},[_c('router-link',{staticStyle:{"color":"white","text-decoration":"none"},attrs:{"to":"/condiciones-de-la-promocion"}},[_vm._v("Condiciones de la promoción")]),_vm._v(" | "),_c('a',{staticStyle:{"color":"white","text-decoration":"none"},attrs:{"target":"new","href":"https://www.pharma-call.com/politica-privacidad/"}},[_vm._v("Política de privacidad")]),_vm._v(" | "),_c('a',{staticStyle:{"color":"white","text-decoration":"none"},attrs:{"target":"new","href":"https://www.pharma-call.com/politica-de-cookies/"}},[_vm._v("Política de cookies")])],1)])])])],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.dialogImport),callback:function ($$v) {_vm.dialogImport=$$v},expression:"dialogImport"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Resultado importación")])]),_c('v-card-text',[_vm._v(" Resultado: "),_c('b',[_vm._v(_vm._s(_vm.importResult))]),_c('br'),_vm._v(" Registros importados: "),_c('b',[_vm._v(_vm._s(_vm.importOk))]),_c('br'),_vm._v(" Registros actualizados: "),_c('b',[_vm._v(_vm._s(_vm.importUpdated))]),_c('br'),_vm._v(" Registros ignorados: "),_c('b',[_vm._v(_vm._s(_vm.importIgnored))]),_c('br'),_c('v-container')],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"default":"","color":"primary"},on:{"click":function($event){_vm.dialogImport = false}}},[_vm._v(" Cerrar ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }